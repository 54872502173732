import React, {useEffect, useImperativeHandle, useMemo, useReducer, useRef, useState} from 'react'
import {useTranslation} from 'next-i18next'
import WizContentModal from '@component/modal/WizContentModal'
import {IKeyCopyfolio} from '@api/key/copyfolio/getKeyCopyfolio'
import ResumeCopyReviewContainer from '@feature/copy/components/ResumeCopyTradingModal/ResumeCopyReviewContainer'
import ResumeSettingContainer from '@feature/copy/components/ResumeCopyTradingModal/ResumeSettingContainer'
import useQueryGetCopyTradingTerms from '@hook/query/portfolio/copyfolio/useQueryGetCopyTradingTerms'
import ResumeCopyTermsContainer from '@feature/copy/components/ResumeCopyTradingModal/ResumeCopyTermsContainer'
import ResumeCopyCompleteContainer from '@feature/copy/components/ResumeCopyTradingModal/ResumeCopyCompleteContainer'
import useSnackbar from '@hook/useSnackbar'
import {apiPostCreateCopier} from '@api/copy/copier/createCopier'
import {apiPostDeleteCopier} from '@api/copy/copier/deleteCopier'
import useQueryGetCopierInfo from '@hook/query/portfolio/copyfolio/useQueryGetCopierInfo'

enum PageSTEP {
    REVIEW,
    SETTING,
    TERMS,
    COMPLETE,
}

interface IProps {
    className?: string
    onDeleteComplete?: () => void
    resumeKeyId: string
}

const initialState = {
    pageStep: PageSTEP.REVIEW,
    selectLeader: undefined,
    selectCopier: undefined,
    isEnableMirror: false,
    isEnableStopLoss: true,
    isErrorStopLoss: false,
    stopLossPercentage: 0,
    isEnableTakeProfit: false,
    isErrorTakeProfit: false,
    takeProfitPercentage: 0,
    isLoadingCreateCopier: false,
    isCheckConfirmCopyTradingTerms: false,
}

const ResumeCopyTradingModal = ({onDeleteComplete, resumeKeyId}: IProps, ref: any) => {
    const modalRef = useRef<any>()
    const {t} = useTranslation()
    const [state, dispatch] = useReducer(reducer, initialState)
    const {data: copierInfo} = useQueryGetCopierInfo(resumeKeyId, false)
    const [copyTradingData, setCopyTradingData] = useState<IKeyCopyfolio>()
    const isOpened = useRef(false)

    const {showErrorSnackbar} = useSnackbar()
    const {data: terms} = useQueryGetCopyTradingTerms(isOpened.current)
    const {
        pageStep,
        isEnableMirror,
        isEnableStopLoss,
        isEnableTakeProfit,
        stopLossPercentage,
        takeProfitPercentage,
        isErrorStopLoss,
        isErrorTakeProfit,
        isCheckConfirmCopyTradingTerms,
    } = state

    const reset = () => dispatch({type: 'reset', payload: initialState})

    const createCopy = async () => {
        const {data: deleteDate, error: deletedError} = await apiPostDeleteCopier(resumeKeyId)

        if (deleteDate) {
            try {
                const {data: resumeCreate, error} = await apiPostCreateCopier({
                    key_id: copyTradingData?.key_id,
                    copy_key_id: copyTradingData?.copy_key_id,
                    name: copyTradingData?.leader_name,
                    type: 'copybot',
                    terms_version: terms?.version,
                    settings: {
                        take_profit: isEnableTakeProfit ? takeProfitPercentage : undefined,
                        stop_loss: isEnableStopLoss ? stopLossPercentage : undefined,
                        mirror: isEnableMirror,
                    },
                })
                if (!error) {
                    showErrorSnackbar(error)
                    return
                } else {
                    onDeleteComplete()
                    dispatch({type: 'page_next'})
                }
            } catch (e) {}
        } else {
            showErrorSnackbar(deletedError)
        }
    }

    useImperativeHandle(ref, () => ({
        show: data => {
            reset()
            setCopyTradingData(data)
            modalRef.current.show()
            isOpened.current = true
        },
    }))

    function reducer(state, action) {
        switch (action.type) {
            case 'field_update':
                return {...state, [action.field]: action.value}
            case 'reset':
                return action.payload
            case 'page_back': {
                let nextPage
                switch (state.pageStep) {
                    case PageSTEP.REVIEW:
                        nextPage = PageSTEP.SETTING
                        break
                    case PageSTEP.TERMS:
                        nextPage = PageSTEP.REVIEW
                        break
                }

                return {
                    ...state,
                    pageStep: nextPage,
                }
            }
            case 'page_next': {
                let nextPage
                switch (state.pageStep) {
                    case PageSTEP.REVIEW:
                        nextPage = PageSTEP.TERMS
                        break
                    case PageSTEP.TERMS:
                        nextPage = PageSTEP.COMPLETE
                        break
                    case PageSTEP.COMPLETE:
                        break
                    case PageSTEP.SETTING:
                        nextPage = PageSTEP.REVIEW
                        break
                }

                return {
                    ...state,
                    pageStep: nextPage,
                }
            }
        }
    }

    const getTitle = useMemo(() => {
        if (pageStep === PageSTEP.REVIEW) {
            return t('copyfolio.resume.modal.head')
        }
        if (pageStep === PageSTEP.SETTING) {
            return t('copyfolio.resume.modal.setting.head')
        }
        if (pageStep === PageSTEP.TERMS) {
            return t('copyfolio.resume.modal.terms.head')
        }
    }, [pageStep, t])

    useEffect(() => {
        dispatch({type: 'field_update', field: 'isEnableMirror', value: copyTradingData?.settings?.mirror})
        dispatch({type: 'field_update', field: 'isErrorStopLoss', value: false})
        dispatch({type: 'field_update', field: 'isEnableStopLoss', value: copyTradingData?.settings?.stop_loss})
        dispatch({type: 'field_update', field: 'isErrorTakeProfit', value: false})
        dispatch({type: 'field_update', field: 'isEnableTakeProfit', value: copyTradingData?.settings?.take_profit})
        dispatch({type: 'field_update', field: 'stopLossPercentage', value: copyTradingData?.settings?.stop_loss})
        dispatch({type: 'field_update', field: 'takeProfitPercentage', value: copyTradingData?.settings?.take_profit})
    }, [copyTradingData])

    const onCloseCallbackFunc = () => {
        setTimeout(() => {
            setCopyTradingData(null)
        }, 500)
    }

    return (
        <WizContentModal title={getTitle} ref={modalRef} size={'800'} onCloseCallback={onCloseCallbackFunc}>
            <div className={'mt-[30px] '}>
                {pageStep === PageSTEP.REVIEW && (
                    <ResumeCopyReviewContainer
                        copyTradingData={copyTradingData}
                        stopLossPercentage={stopLossPercentage}
                        takeProfitPercentage={takeProfitPercentage}
                        isEnableMirror={isEnableMirror}
                        isEnableStopLoss={isEnableStopLoss}
                        isEnableTakeProfit={isEnableTakeProfit}
                        onClickResume={() => dispatch({type: 'page_next'})}
                        onClickSetting={() => dispatch({type: 'page_back'})}
                        data={copierInfo}
                    />
                )}
                {pageStep === PageSTEP.SETTING && (
                    <ResumeSettingContainer
                        userBalance={copyTradingData?.balance}
                        enableMirrorMode={isEnableMirror}
                        enableStopLoss={isEnableStopLoss}
                        enableTakeProfit={isEnableTakeProfit}
                        onChangeMirrorMode={isEnable =>
                            dispatch({type: 'field_update', field: 'isEnableMirror', value: isEnable})
                        }
                        onChangeStopLoss={isEnable => {
                            dispatch({type: 'field_update', field: 'isErrorStopLoss', value: false})
                            dispatch({type: 'field_update', field: 'isEnableStopLoss', value: isEnable})
                        }}
                        onChangeTakeProfit={isEnable => {
                            dispatch({type: 'field_update', field: 'isErrorTakeProfit', value: false})
                            dispatch({type: 'field_update', field: 'isEnableTakeProfit', value: isEnable})
                        }}
                        isErrorStopLoss={isErrorStopLoss}
                        isErrorTakeProfit={isErrorTakeProfit}
                        stopLossPercentage={stopLossPercentage}
                        takeProfitPercentage={takeProfitPercentage}
                        onChangeStopLossPercentage={number => {
                            dispatch({type: 'field_update', field: 'isErrorStopLoss', value: false})
                            dispatch({type: 'field_update', field: 'stopLossPercentage', value: number})
                        }}
                        onChangeTakeProfitPercentage={number => {
                            dispatch({type: 'field_update', field: 'isErrorTakeProfit', value: false})
                            dispatch({type: 'field_update', field: 'takeProfitPercentage', value: number})
                        }}
                        onClickNext={() => {
                            if (isEnableStopLoss && stopLossPercentage < 3) {
                                dispatch({type: 'field_update', field: 'isErrorStopLoss', value: true})
                                return
                            }

                            if (isEnableTakeProfit && takeProfitPercentage < 3) {
                                dispatch({type: 'field_update', field: 'isErrorTakeProfit', value: true})
                                return
                            }
                            dispatch({type: 'page_next'})
                        }}
                    />
                )}
                {pageStep === PageSTEP.TERMS && (
                    <ResumeCopyTermsContainer
                        isCheckConfirmCopyTradingTerms={isCheckConfirmCopyTradingTerms}
                        onChangeCheckConfirmCopyTradingTerms={isEnable => {
                            dispatch({
                                type: 'field_update',
                                field: 'isCheckConfirmCopyTradingTerms',
                                value: isEnable,
                            })
                        }}
                        terms={terms?.terms}
                        onClickBack={() => dispatch({type: 'page_back'})}
                        onClickNext={() => {
                            createCopy()
                        }}
                    />
                )}
                {pageStep === PageSTEP.COMPLETE && (
                    <ResumeCopyCompleteContainer
                        onClickUnderStand={() => {
                            modalRef?.current?.close()
                        }}
                    />
                )}
            </div>
        </WizContentModal>
    )
}

export default React.forwardRef(ResumeCopyTradingModal)
