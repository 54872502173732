import React, {useImperativeHandle, useRef} from 'react'
import {useTranslation} from 'next-i18next'
import WizContentModal from '@component/modal/WizContentModal'
import Text from '@component/Text/Text'
import useFormatNumber from '@hook/useFormatNumber'
import {useRouter} from 'next/router'
import TagText from '@component/Text/TagText'

interface IProps {
    loss?: number
}

const LossRecoveryModal = ({loss}: IProps, ref: any) => {
    const modalRef = useRef<any>()
    const router = useRouter()
    const {t} = useTranslation()
    const {displayBalance} = useFormatNumber()

    useImperativeHandle(ref, () => ({
        show: () => {
            modalRef.current.show()
        },
    }))
    return (
        <WizContentModal title={t('copyfolio.copyTrading.recovery.modal.title')} ref={modalRef} size={'400'}>
            <div
                className={
                    'pt-[30px] pb-[10px] flex justify-between border-b border-b-gray08 dark:border-b-dark_gray08'
                }>
                <Text className={'text-h3 text-blue_shade dark:text-dark_blue_shade'}>
                    {t('copyfolio.copyTrading.recovery.modal.loss')}
                </Text>
                <Text className={'text-h3 text-blue_shade dark:text-dark_blue_shade'}>
                    {displayBalance(loss ?? 0, {showPreSign: true, isUSD: true})}
                </Text>
            </div>
            <Text className={'text-ti2 text-gray02 dark:text-dark_gray02 mt-[20px]'}>
                {t('copyfolio.copyTrading.recovery.modal.content.title')}
            </Text>
            <TagText className={'text-body2 text-gray02 dark:text-dark_gray02 mt-[5px]'}>
                {t('copyfolio.copyTrading.recovery.modal.content.text')}
            </TagText>
        </WizContentModal>
    )
}

export default React.forwardRef(LossRecoveryModal)
