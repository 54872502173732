import React, {useRef} from 'react'
import Text from '@component/Text/Text'
import UserThumbnail from '@component/images/UserThumbnail'
import Span from '@component/Text/Span'
import IconNext from '@svg/common/ic_next.svg'
import IconDownArrow from '@svg/common/ic_down_arrow.svg'
import ExchangeTag from '@component/tags/ExchangeTag'
import {getNumberColorClass} from '@util/etc'
import {useTranslation} from 'react-i18next'
import useQueryFetchMe from '@hook/query/account/useQueryFetchMe'
import useQueryGetExchanges from '@hook/query/core/exchange/useQueryGetExchanges'
import useFormatNumber from '@hook/useFormatNumber'
import WizButton from '@component/buttons/WizButton'
import {IKeyCopyfolio} from '@api/key/copyfolio/getKeyCopyfolio'
import IconInfoRound from '@svg/common/ic_info_round.svg'
import {useRouter} from 'next/router'
import LossRecoveryModal from '@feature/copy/components/LossRecoveryModal/LossRecoveryModal'
import TagText from '@component/Text/TagText'
import WizTooltip from '@component/tooltip/WizTooltip'

interface IProps {
    className?: string
    copyTradingData?: IKeyCopyfolio
    stopLossPercentage?: number
    takeProfitPercentage?: number
    isEnableMirror?: boolean
    isEnableStopLoss?: boolean
    isEnableTakeProfit?: boolean
    onClickSetting?: () => void
    onClickResume?: () => void
    data: IKeyCopyfolio
}

const ResumeCopyReviewContainer: React.FC<IProps> = ({
    className,
    copyTradingData,
    stopLossPercentage,
    takeProfitPercentage,
    isEnableMirror,
    isEnableTakeProfit,
    isEnableStopLoss,
    onClickResume,
    onClickSetting,
    data,
}) => {
    const {t} = useTranslation()
    const {getExchange} = useQueryGetExchanges()
    const {displayBalance, displayPercent} = useFormatNumber()
    const {data: me} = useQueryFetchMe()
    const router = useRouter()
    const lossRecoveryModalRef = useRef<any>(null)
    return (
        <>
            <Text className={'text-gray01 dark:text-dark_gray01'}>{t('copyfolio.resume.modal.review.desc')}</Text>
            <div
                className={
                    'bg-gray09 dark:bg-bg_dark_white02 border border-gray06 dark:border-dark_gray06 rounded-[5px] pt-[30px] px-[70px] sm:px-[20px] pb-[20px] mt-[10px]'
                }>
                <div className={`flex sm:flex-col sm:gap-y-[10px]`}>
                    <div className={'flex-1 flex flex-col items-center'}>
                        <div className={'relative'}>
                            <UserThumbnail src={me?.thumbnail} size={60} />
                            <div
                                className={
                                    'absolute bottom-0 right-[-3px] bg-blue dark:bg-dark_blue py-[2.5px] px-[5px] rounded-[10px]'
                                }>
                                <Text className={'text-ti4 text-white'}>{t('keyCopyfolio.copyTradingOption.me')}</Text>
                            </div>
                        </div>
                        <Text className={'mt-[5px] sm:mt-[0px]'}>
                            <Span className={'ml-[5px] text-ti2 text-gray01 dark:text-dark_gray01'}>
                                {copyTradingData?.original_key_name ||
                                    getExchange(copyTradingData?.exchange)?.display_name}
                            </Span>
                        </Text>
                    </div>
                    <div
                        className={'flex-none flex flex-col justify-center sm:flex-row sm:gap-x-[5px] sm:items-center'}>
                        <IconNext className={'w-[30px] sm:hidden fill-blue dark:fill-dark_blue'} />
                        <IconDownArrow className={'hidden sm:block fill-blue dark:fill-dark_blue w-[20px]'} />
                        <Text className={'text-ti2 text-blue dark:text-dark_blue mt-[5px] text-center sm:mt-[0px]'}>
                            {t('keyCopyfolio.copyTradingOption.copy')}
                        </Text>
                    </div>
                    <div className={'flex-1 flex flex-col items-center'}>
                        <div>
                            <div className={'relative'}>
                                <UserThumbnail src={copyTradingData?.copy_key?.thumbnail} size={60} />
                                <div
                                    className={
                                        'absolute bottom-0 left-[40px] bg-leader dark:bg-dark_leader py-[2.5px] px-[5px] rounded-[10px]'
                                    }>
                                    <Text className={'text-ti4 text-white'}>
                                        {t('keyCopyfolio.copyTradingOption.leader')}
                                    </Text>
                                </div>
                            </div>
                        </div>
                        <Text className={'mt-[5px] sm:mt-[0px]'}>
                            <Span className={'ml-[5px] text-ti2 text-gray01 dark:text-dark_gray01'}>
                                {copyTradingData?.copy_key?.copy_lead_name}
                            </Span>
                        </Text>
                    </div>
                </div>

                <div className={'mt-[20px] grid grid-cols-2 gap-y-[13.5px]'}>
                    <Text className={'text-ti2 text-gray02 dark:text-dark_gray02'}>
                        {t('modal.createCopy.step4.review.exchange.title')}
                    </Text>
                    <div className={'flex justify-end items-center'}>
                        <ExchangeTag exchange={copyTradingData?.exchange} textClassName={'text-ti1'} />
                    </div>
                    <Text className={'text-ti2 text-gray02 dark:text-dark_gray02'}>
                        {t('modal.createCopy.step4.review.mirror.title')}
                    </Text>
                    <Text className={'text-right text-ti1 text-gray01 dark:text-dark_gray01'}>
                        {isEnableMirror
                            ? t('modal.createCopy.step4.review.mirror.on')
                            : t('modal.createCopy.step4.review.mirror.off')}
                    </Text>
                    <Text className={'text-ti2 text-gray02 dark:text-dark_gray02'}>
                        {t('modal.createCopy.step4.review.stopLoss.title')}
                    </Text>
                    <div className={'flex gap-x-[3px] justify-end'}>
                        {isEnableStopLoss ? (
                            <>
                                <Text className={'text-gray03 dark:text-dark_gray03 text-ti1'}>
                                    {t('modal.createCopy.step4.review.est', {
                                        balance: displayBalance(
                                            copyTradingData?.balance * (stopLossPercentage / 100) * -1.0,
                                            {
                                                showPreSign: true,
                                            },
                                        ),
                                    })}
                                </Text>
                                <Text className={`text-ti1 ${getNumberColorClass(stopLossPercentage * -1.0)}`}>
                                    {displayPercent(stopLossPercentage * -1.0)}
                                </Text>
                            </>
                        ) : (
                            <Text className={'text-right text-ti1 text-gray01 dark:text-dark_gray01'}>
                                {t('modal.createCopy.step4.review.stopLoss.off')}
                            </Text>
                        )}
                    </div>
                    <Text className={'text-ti2 text-gray02 dark:text-dark_gray02'}>
                        {t('modal.createCopy.step4.review.takeProfit.title')}
                    </Text>
                    <div className={'flex gap-x-[3px] justify-end'}>
                        {isEnableTakeProfit ? (
                            <>
                                <Text className={'text-gray03 dark:text-dark_gray03 text-ti1'}>
                                    {t('modal.createCopy.step4.review.est', {
                                        balance: displayBalance(
                                            copyTradingData?.balance * (takeProfitPercentage / 100),
                                            {
                                                showPreSign: true,
                                            },
                                        ),
                                    })}
                                </Text>
                                <Text className={`text-right text-ti1 ${getNumberColorClass(takeProfitPercentage)}`}>
                                    {displayPercent(takeProfitPercentage)}
                                </Text>
                            </>
                        ) : (
                            <Text className={'text-right text-ti1 text-gray01 dark:text-dark_gray01'}>
                                {t('modal.createCopy.step4.review.takeProfit.off')}
                            </Text>
                        )}
                    </div>
                </div>
            </div>
            {data?.next_owed_earning && (
                <WizTooltip
                    type={'copytrading_loss_recovery_tooltip'}
                    title={t('copyfolio.copyTrading.recovery.modal.content.title')}
                    placement={'top'}
                    onClick={e => e.stopPropagation()}
                    render={
                        <div
                            className={
                                'flex items-center justify-center mt-[15px] sm:inline sm:text-center cursor-pointer'
                            }>
                            {router?.locale === 'ko' ? (
                                <>
                                    <Text className={'text-body3 text-gray02 dark:text-dark_gray02 sm:inline'}>
                                        {t('copyfolio.copyTrading.recovery.table.text')}
                                    </Text>
                                    <Text className={'text-blue_shade dark:text-dark_blue_shade text-ti3 sm:inline'}>
                                        {t('copyfolio.copyTrading.recovery')}
                                    </Text>
                                    <IconInfoRound
                                        className={'w-[12px] fill-gray02 dark:fill-dark_gray02 ml-[2px] sm:inline'}
                                    />
                                    <Text className={'text-body3 text-gray02 dark:text-dark_gray02 sm:inline'}>
                                        {t('copyfolio.copyTrading.recovery.table.text.ko')}
                                    </Text>
                                </>
                            ) : (
                                <>
                                    <Text className={'text-blue_shade dark:text-dark_blue_shade text-ti3 sm:inline'}>
                                        {t('copyfolio.copyTrading.recovery')}
                                    </Text>
                                    <IconInfoRound
                                        className={'w-[12px] fill-gray02 dark:fill-dark_gray02 ml-[2px] sm:inline'}
                                    />
                                    <Text className={'text-body3 text-gray02 dark:text-dark_gray02 sm:inline'}>
                                        {t('copyfolio.copyTrading.recovery.table.text')}
                                    </Text>
                                </>
                            )}
                        </div>
                    }>
                    <TagText className={'text-body2 text-gray02 dark:text-dark_gray02'}>
                        {t('copyfolio.copyTrading.recovery.modal.content.text')}
                    </TagText>
                    <Text className={'text-ti2 mt-[20px] text-blue_shade dark:text-dark_blue_shade'}>
                        {t('copyfolio.copyTrading.recovery.modal.loss')}:{' '}
                        {displayBalance(data?.next_owed_earning, {showPreSign: true, isUSD: true})}
                    </Text>
                </WizTooltip>
            )}
            <div className={`mt-[30px] flex items-center gap-x-[10px] justify-center`}>
                <WizButton
                    onClick={onClickSetting}
                    text={t('copyfolio.resume.modal.review.changeSetting')}
                    buttonType={'border'}
                />
                <WizButton
                    onClick={onClickResume}
                    text={t('copyfolio.resume.modal.review.resumeCopyTrading')}
                    buttonType={'secondary'}
                />
            </div>
            <LossRecoveryModal
                ref={lossRecoveryModalRef}
                loss={data?.status === 'running' ? data?.owed_earning : data?.next_owed_earning}
            />
        </>
    )
}

export default ResumeCopyReviewContainer
