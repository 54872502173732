import React from 'react'
import CreateCopyDoneContainer from '@feature/copy/components/CreateCopyModal/CreateCopyDoneContainer/CreateCopyDoneContainer'
import WizButton from '@component/buttons/WizButton'
import {useTranslation} from 'next-i18next'

interface IProps {
    className?: string
    onClickUnderStand?: () => void
}

const ResumeCopyCompleteContainer: React.FC<IProps> = ({className, onClickUnderStand}) => {
    const {t} = useTranslation()
    return (
        <div>
            <CreateCopyDoneContainer />
            <div className={'mt-[40px]'}>
                <WizButton
                    className={'w-[500px] sm:w-[250px] mx-auto'}
                    text={t('copyfolio.resume.modal.complete.remind.understand')}
                    onClick={onClickUnderStand}
                />
            </div>
        </div>
    )
}

export default ResumeCopyCompleteContainer
