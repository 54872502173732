import React from 'react'
import Text from '@component/Text/Text'

interface IProps {
    className?: string
    totalStep: number
    currentStep: number
}

const StepIndicator: React.FC<IProps> = ({className, totalStep, currentStep}) => {
    const getTextClass = (isSelected: boolean) => {
        if (isSelected) return 'text-white'
        else return 'text-gray04 dark:text-dark_gray04'
    }

    return (
        <div className={`flex space-x-[10px] items-center ${className}`}>
            {[...Array(totalStep)].map((e, i) => {
                const isSelected = i === currentStep

                return (
                    <div
                        key={i}
                        className={`${
                            isSelected
                                ? 'w-[30px] h-[30px] bg-primary dark:bg-dark_primary'
                                : 'w-[20px] h-[20px] bg-gray07 dark:bg-dark_gray07'
                        } rounded-full flex justify-center items-center`}>
                        <Text className={`text-ti3 ${getTextClass(isSelected)}`}>{i + 1}</Text>
                    </div>
                )
            })}
        </div>
    )
}

export default StepIndicator
