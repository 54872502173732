import React, {useImperativeHandle, useRef} from 'react'
import {useTranslation} from 'next-i18next'
import WizContentModal from '@component/modal/WizContentModal'
import WizIconButton from '@component/buttons/WizIconButton'
import useCopy from '@hook/useCopy'
import {useRouter} from 'next/router'
import useSnackbar from '@hook/useSnackbar'
import WizImage from '@component/images/WizImage'
import ic_facebook from '@image/ic_footer_sns_facebook.png'
import IconX from '@svg/sns/ic_x.svg'
import ic_kakao from '@image/ic_kakao.png'
import ic_mail from '@image/ic_icon_mail.png'
import Text from '@component/Text/Text'
import WizButton from '@component/buttons/WizButton'
import Script from 'next/script'
import {KAKAO_KEY} from '@constant/auth'
import IconCopyLink from '@svg/common/ic_copy_link.svg'
import WizIcon from '@component/images/WizIcon'

interface IProps {
    className?: string
    viewList?: string[]
    path?: string
}

const SNS_ICON_SIZE = 36
const ShareLinkModal = ({className, path, viewList}: IProps, ref) => {
    const modalRef = useRef<any>()
    const {t} = useTranslation()
    const {copyToClipboard} = useCopy()
    const router = useRouter()
    const {showSnackbar} = useSnackbar()

    useImperativeHandle(ref, () => ({
        show: () => {
            modalRef.current.show()
        },
    }))
    const onClickShare = shareType => {
        switch (shareType) {
            case 'facebook':
                window.open(
                    `https://www.facebook.com/sharer/sharer.php?u=${window.location.origin}/${router.locale}${path}`,
                    '_blank',
                    'width=600,height=400',
                )
                modalRef?.current?.close()
                break
            case 'twitter':
                window.open(
                    'https://twitter.com/share?url=' +
                        `${t('modal.share.content.title')} : ${window.location.origin}/${router.locale}${path}`,
                    '_blank',
                    'width=600,height=400',
                )
                modalRef?.current?.close()
                break
            case 'kakaotalk':
                if (!window.Kakao.isInitialized()) {
                    window.Kakao.init(KAKAO_KEY)
                }
                window.Kakao.Link.sendDefault({
                    objectType: 'feed',
                    content: {
                        title: t('metaTag.title'),
                        imageUrl: 'https://leagueoftraders.io/images/img_meta_thumbnail.png',
                        link: {
                            mobileWebUrl: `${window.location.origin}/${router.locale}${path}`,
                            webUrl: `${window.location.origin}/${router.locale}${path}`,
                        },
                        imageWidth: 800,
                    },
                    installTalk: true,
                })
                break
            case 'mail':
                window.location.href = `mailto:?body=${t('modal.share.content.title')} : ${window.location.origin}/${
                    router.locale
                }${path}`
                modalRef?.current?.close()
                break
            case 'link':
                copyToClipboard(`${window.location.origin}/${router.locale}${path}`)
                showSnackbar(t('portfolio.share.result.success'))
                modalRef?.current?.close()
                break
        }
    }

    return (
        <>
            <Script strategy={'lazyOnload'} src="/kakao.min.js" crossOrigin={'anonymous'} />
            <WizContentModal size={'400'} title={t('modal.share.title')} ref={modalRef}>
                <div
                    className={`py-[25px] grid ${
                        !viewList || viewList?.length === 0 ? 'grid-cols-5' : `grid-cols-${viewList?.length}`
                    } text-center items-center justify-center`}>
                    {(!viewList || viewList?.length === 0 || viewList?.find(item => item === 'facebook')) && (
                        <WizIconButton className={'rounded p-[5px]'} onClick={() => onClickShare('facebook')}>
                            <WizImage
                                src={ic_facebook}
                                containerClassName={'inline-block'}
                                width={SNS_ICON_SIZE}
                                height={SNS_ICON_SIZE}
                            />
                            <Text className={'text-body3 text-gray02 dark:text-dark_gray02'}>
                                {t('modal.share.facebook.text')}
                            </Text>
                        </WizIconButton>
                    )}
                    {(!viewList || viewList?.length === 0 || viewList?.find(item => item === 'x')) && (
                        <WizIconButton className={'rounded p-[5px]'} onClick={() => onClickShare('twitter')}>
                            <div className={'inline-block'}>
                                <IconX width={SNS_ICON_SIZE} height={SNS_ICON_SIZE} />
                            </div>

                            <Text className={'text-body3 text-gray02 dark:text-dark_gray02'}>
                                {t('modal.share.x.text')}
                            </Text>
                        </WizIconButton>
                    )}
                    {(!viewList || viewList?.length === 0 || viewList?.find(item => item === 'kakao')) && (
                        <WizIconButton className={'rounded p-[5px]'} onClick={() => onClickShare('kakaotalk')}>
                            <WizImage
                                src={ic_kakao}
                                containerClassName={'inline-block'}
                                width={SNS_ICON_SIZE}
                                height={SNS_ICON_SIZE}
                            />
                            <Text className={'text-body3 text-gray02 dark:text-dark_gray02'}>
                                {t('modal.share.kakao.text')}
                            </Text>
                        </WizIconButton>
                    )}
                    {(!viewList || viewList?.length === 0 || viewList?.find(item => item === 'mail')) && (
                        <WizIconButton className={'rounded p-[5px]'} onClick={() => onClickShare('mail')}>
                            <WizImage
                                src={ic_mail}
                                containerClassName={'inline-block'}
                                width={SNS_ICON_SIZE}
                                height={SNS_ICON_SIZE}
                            />
                            <Text className={'text-body3 text-gray02 dark:text-dark_gray02'}>
                                {t('modal.share.mail.text')}
                            </Text>
                        </WizIconButton>
                    )}
                    {(!viewList || viewList?.length === 0 || viewList?.find(item => item === 'link')) && (
                        <WizIconButton className={'rounded p-[5px]'} onClick={() => onClickShare('link')}>
                            <WizIcon
                                backgroundColorClassName={
                                    'w-min mx-auto bg-gray07 dark:bg-dark_gray07 p-[7px] inline-block'
                                }>
                                <IconCopyLink className={'fill-gray02 dark:fill-dark_gray02 w-[20px]'} />
                            </WizIcon>
                            <Text className={'text-body3 text-gray02 dark:text-dark_gray02'}>
                                {t('modal.share.link.text')}
                            </Text>
                        </WizIconButton>
                    )}
                </div>
                <WizButton
                    text={t('modal.share.button.cancel')}
                    buttonType={'border'}
                    className={'w-full'}
                    onClick={() => modalRef?.current?.close()}
                />
            </WizContentModal>
        </>
    )
}

export default React.forwardRef(ShareLinkModal)
