import React, {useMemo} from 'react'
import {useTranslation} from 'next-i18next'
import {useRouter} from 'next/router'
import WizCheckBox from '@component/inputs/WizCheckBox'
import Text from '@component/Text/Text'
import WizButton from '@component/buttons/WizButton'
import WizMarkdown from '@component/markdown/WizMarkdown'

interface IProps {
    className?: string
    isCheckConfirmCopyTradingTerms?: boolean
    onChangeCheckConfirmCopyTradingTerms?: (isCheck?: boolean) => void
    terms?: string
    onClickBack?: () => void
    onClickNext?: () => void
}

const ResumeCopyTermsContainer: React.FC<IProps> = ({
    className,
    terms,
    isCheckConfirmCopyTradingTerms,
    onChangeCheckConfirmCopyTradingTerms,
    onClickBack,
    onClickNext,
}) => {
    const {t} = useTranslation()
    const router = useRouter()

    const lotTermText = useMemo(() => {
        return ``
    }, [])

    return (
        <div className={'mt-[30px]'}>
            <Text className={'text-gray01 dark:text-dark_gray01'}>{t('copyfolio.resume.modal.terms.desc')}</Text>
            <div
                className={`mt-[10px] px-[40px] pt-[10px] pb-[30px] bg-gray09 dark:bg-bg_dark_white02 border border-gray06 dark:border-dark_gray06 rounded-[5px] ${className}`}>
                <WizMarkdown
                    content={terms}
                    className={'text-gray02 dark:text-dark_gray02 text-body2'}
                    customClassName={{
                        h3: 'text-[16px] leading-[24px] text-gray01 dark:text-dark_gray01 pt-[0px] mt-[20px]',
                        ul: 'mt-[0px] mb-[0px] pt-[5px] pb-[0px] ml-[10px] pl-[10px]',
                        ol: 'mt-[0px] mb-[0px] pt-[5px] pb-[0px] ml-[10px] pl-[10px]',
                    }}
                />
            </div>
            <div className={'mt-[30px] mb-[10px] flex justify-center'}>
                <WizCheckBox
                    text={t('copyfolio.resume.modal.terms.checkbox')}
                    checked={isCheckConfirmCopyTradingTerms}
                    onChanged={check => onChangeCheckConfirmCopyTradingTerms(check)}
                    textClassName={'text-body2 text-gray01 dark:text-dark_gray01'}
                />
            </div>
            <div className={'flex items-center gap-x-[10px] justify-center'}>
                <WizButton text={t('copyfolio.resume.modal.terms.back')} buttonType={'border'} onClick={onClickBack} />
                <WizButton
                    text={t('copyfolio.resume.modal.terms.next')}
                    buttonType={'secondary'}
                    disabled={!isCheckConfirmCopyTradingTerms}
                    onClick={onClickNext}
                />
            </div>
        </div>
    )
}

export default ResumeCopyTermsContainer
